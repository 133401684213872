import React from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";

const defaultProps = {
  defaultValue: "",
  required: false,
  placeholder: undefined,
  hint: undefined,
  inputClass: undefined,
  dataAction: undefined,
};

function DateInput(props) {
  return (
    <div className="form-element">
      <label htmlFor={props.name} className="form-input-label">
        {props.label}{" "}
        {props.required ? <abbr title="Obrigatório">*</abbr> : null}
      </label>

      <div className="relative">
        <Flatpickr
          className={props.inputClass || "form-input cursor-pointer"}
          id={props.name}
          name={props.name}
          options={{
            dateFormat: "Y-m-d",
            altInput: true,
            altFormat: "d/m/Y",
            locale: Portuguese,
          }}
          data-action={props.dataAction}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          required={props.required}
        />

        <div className="absolute inset-y-0 right-0 flex items-center pr-4 text-slate-400 pointer-events-none">
          <iconify-icon icon="ri:calendar-line" />
        </div>
      </div>

      <p className="form-input-hint-message">{props.hint}</p>
    </div>
  );
}

DateInput.defaultProps = defaultProps;
DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hint: PropTypes.string,
  inputClass: PropTypes.string,
  dataAction: PropTypes.string,
};

export default DateInput;
